<template>
    <div>
        <section id="footer" class="whats-new-bg p-5 pb-3 showdesktopdiv">
            <div class="row">
                <div class="col-md-3 col-12 d-flex align-items-center column-p text-left w-30">
                    <img src="/assets/images/logo.png" alt="logo" width="200" height="200" />
                </div>
                <div class="col-md-3 col-12 column-p w-22">
                    <h2 class="text-capitalize footer-label-header">navigation</h2>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/modern-tech-solutions-for-digitally-viksit-bharat">Home</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/products-cutting-edge-tools-government">Products</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/blogs/ai-ml-solutions-for-government-agencies">Blogs</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/careers/work-with-goa-tech-startup">Careers</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/about-samruddh-bharat-technologies-goa-tech-startup">About us</router-link>
                    </p>
                </div>
                <div class="col-md-3 col-12 column-p">
                    <h2 class="text-capitalize footer-label-header">legal</h2>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/terms-of-use" target="_blank">Terms of use</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/privacy-policy" target="_blank">privacy policy</router-link>
                    </p>
                </div>
                <div class="col-md-3 col-12 column-p w-23 showdesktopdiv">
                    <!-- <h2 class="text-capitalize footer-contact-header">contact</h2>
                    <p class="footer-label-text mb-2 mt-4"></p>
                    <p class="footer-label-text">info@samruddhbharattech.in</p> -->

                    <h3 class="text-capitalize footer-contact-subheader ">social media</h3>
                    <div>
                        <span class="me-2">
                            <a href="https://www.linkedin.com/company/samruddh-bharat-technologies" target="_blank">
                                <img src="/assets/images/bg-white-linkdin.png" alt="linkdin-image" width="35"
                                    height="35" />
                            </a>
                        </span>
                        <!-- <span class="me-2">
                            <a href="https://www.instagram.com" target="_blank">
                                <img src="/assets/images/bg-white-insta.png" alt="instagram-image" class="mx-2" width="35"
                                    height="35" />
                            </a>
                        </span>
                        <span>
                            <a href="https://www.facebook.com" target="_blank">
                                <img src="/assets/images/bg-white-fb.png" alt="facebook-image" width="35"
                                    height="35" />
                            </a>
                        </span> -->
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="pt-3 d-flex justify-content-between">
                    <div class="copyright-text ps-5">© {{ new Date().getFullYear() }} All rights reserved by Samruddh Bharat Technologies Private Limited</div>
                    <div class="pe-5">
                        <span class="me-3">
                            <router-link @click="scrollToTop()" class="footer-tearm-link" to="/terms-of-use" target="_blank">Terms of use</router-link>
                        </span>
                        <span>
                            <router-link @click="scrollToTop()" class="footer-tearm-link" to="/privacy-policy" target="_blank">Policy Service</router-link>
                        </span>
                    </div>
                </div>
            </div>
        </section>
        <!-- mobile footer stat here -->
        <section id="footer" class="whats-new-bg mobile-side-padding showmobilediv">
            <div class="row">
                <div class="col-md-3 col-12 d-flex align-items-center justify-content-between column-p text-left w-100 mb-4">
                    <img src="/assets/images/logo.png" alt="logo" width="150" height="150" />
                    <div class="showmobilediv">
                        <h3 class="text-capitalize mob-social-media-baner">social media</h3>
                        <span class="me-2">
                            <a href="https://www.linkedin.com/company/samruddh-bharat-technologies" target="_blank">
                                <img src="/assets/images/bg-white-linkdin.png" alt="linkdin-image" width="42"
                                    height="42" />
                            </a>
                        </span>
                        <!-- <span class="me-2">
                            <a href="https://www.instagram.com" target="_blank">
                                <img src="/assets/images/bg-white-insta.png" alt="instagram-image" class="mx-2" width="42"
                                    height="42" />
                            </a>
                        </span>
                        <span class="me-2">
                            <a href="https://www.facebook.com" target="_blank">
                                <img src="/assets/images/bg-white-fb.png" alt="facebook-image" width="42"
                                    height="42" />
                            </a>
                        </span> -->
                    </div>
                </div>
                <div class="col-md-3 col-12 column-p w-100">
                    <h2 class="text-capitalize footer-label-header">navigation</h2>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/modern-tech-solutions-for-digitally-viksit-bharat">Home</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/products-cutting-edge-tools-government">Products</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/blogs/ai-ml-solutions-for-government-agencies">Blogs</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/careers/work-with-goa-tech-startup">Careers</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/about-samruddh-bharat-technologies-goa-tech-startup">About us</router-link>
                    </p>
                </div>
                <div class="col-md-3 col-12 column-p">
                    <h2 class="text-capitalize footer-label-header">legal</h2>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/terms-of-use" target="_blank">Terms of use</router-link>
                    </p>
                    <p class="text-capitalize footer-label-link">
                        <router-link @click="scrollToTop()" to="/privacy-policy" target="_blank">privacy policy</router-link>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="pt-3 ">
                    <div class="copyright-text mb-3">© {{ new Date().getFullYear() }} All rights reserved by Samruddh Bharat Technologies Private Limited</div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
  methods: {
    scrollToTop() {
        window.scrollTo(0,0);
    }
  },
};
</script>