<template>
  <div v-if="$route.meta.requiresAuth">
      <Header />
      <div class="layout-content-inner">
        <router-view />
      </div>
      <Footer />
  </div>
  <div v-if="!$route.meta.requiresAuth">
      <div>
        <router-view />
      </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>