<template>
    <div>
        <nav class="navbar headernavbar navbar-light navbar-expand-lg fixed-top">
            <div class="container-fluid">
                <div class="logo-outer">
                    <a className='navbar-header-logo' href="/">
                        <img src='/assets/images/logo-2.png' alt='logo' height="72" />
                    </a>
                </div>
                <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button> -->
                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="offcanvasNavbarLabel"><img src='/assets/images/logo-2.png' alt='logo' height="100" /></h5>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body showdesktopdiv">
                        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3 ms-auto navbar-menu-margin">
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTop()" to="/modern-tech-solutions-for-digitally-viksit-bharat">Home</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTop()" to="/about-samruddh-bharat-technologies-goa-tech-startup">About us</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTop()" to="/products-cutting-edge-tools-government">Products</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTop()" to="/blogs/ai-ml-solutions-for-government-agencies">Blogs</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTop()" to="/recognitions/goa-tech-startup">Recognitions</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTop()" to="/careers/work-with-goa-tech-startup">Careers</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTop()" to="/contact/samruddh-bharat-technologies-contact">Contact us</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="offcanvas-body showmobilediv">
                        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3 ms-auto navbar-menu-margin">
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTopMob()" to="/modern-tech-solutions-for-digitally-viksit-bharat">Home</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTopMob()" to="/about-samruddh-bharat-technologies-goa-tech-startup">About us</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTopMob()" to="/products-cutting-edge-tools-government">Products</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTopMob()" to="/blogs/ai-ml-solutions-for-government-agencies">Blogs</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTopMob()" to="/recognitions/goa-tech-startup">Recognitions</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTopMob()" to="/careers/work-with-goa-tech-startup">Careers</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="router-link nav-link" @click="scrollToTopMob()" to="/contact/samruddh-bharat-technologies-contact">Contact us</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
export default {
  methods: {
    scrollToTopMob() {
        var elementid = document.getElementById("offcanvasNavbar");
        elementid.classList.remove("show");
        document.getElementById("offcanvasNavbar").style.visibility = "hidden";
        document.getElementById("mainbody").style.overflow = "auto";
        
        const collection = document.getElementsByClassName("offcanvas-backdrop fade");
        collection[0].classList.remove("show");
        window.scrollTo(0,0);
    },
    scrollToTop() {
        window.scrollTo(0,0);
    }
  },
};
</script>
