import { createRouter, createWebHistory } from 'vue-router';
const routes = [
  {
    path: "/",
    name: "Home",
    exact: true,
    component: () => import("./pages/Home.vue"),
    meta: {
      title: 'Samruddh Bharat Technologies',
      requiresAuth: true,
    }
  },

  {
    path: "/modern-tech-solutions-for-digitally-viksit-bharat",
    name: "HomePage",
    exact: true,
    component: () => import("./pages/Home.vue"),
    meta: {
      title: 'Modern Tech Solutions for Digitally Viksit Bharat',
      requiresAuth: true,
    }
  },
  {
    path: "/blogs/ai-ml-solutions-for-government-agencies",
    name: "BlogsPage",
    exact: true,
    component: () => import("./pages/Blogs.vue"),
    meta: {
      title: 'AI ML Solutions for Government Agencies',
      requiresAuth: true,
    }
  },
  {
    path: "/careers/work-with-goa-tech-startup",
    name: "CareersPage",
    exact: true,
    component: () => import("./pages/Careers.vue"),
    meta: {
      title: 'Work with Goa Tech Startup',
      requiresAuth: true,
    }
  },
  {
    path: "/recognitions/goa-tech-startup",
    name: "RecognitionsPage",
    exact: true,
    component: () => import("./pages/Recognitions.vue"),
    meta: {
      title: 'Goa Tech Startup',
      requiresAuth: true,
    }
  },
  {
    path: "/products-cutting-edge-tools-government",
    name: "ProductPage",
    exact: true,
    component: () => import("./pages/Product.vue"),
    meta: {
      title: 'Cutting Edge Tools for Government',
      requiresAuth: true,
    }
  },
  {
    path: "/contact/samruddh-bharat-technologies-contact",
    name: "ContactPage",
    exact: true,
    component: () => import("./pages/Contact.vue"),
    meta: {
      title: 'Samruddh Bharat Technologies Contact',
      requiresAuth: true,
    }
  },
  {
    path: "/about-samruddh-bharat-technologies-goa-tech-startup",
    name: "AboutPage",
    exact: true,
    component: () => import("./pages/About.vue"),
    meta: {
      title: 'Samruddh Bharat Technologies',
      requiresAuth: true,
    }
  },
  {
    path: "/terms-of-use",
    name: "Terms",
    exact: true,
    component: () => import("./pages/Terms.vue"),
    meta: {
      title: 'Terms of Use',
      requiresAuth: true,
    }
  },
  {
    path: "/privacy-policy",
    name: "Policy",
    exact: true,
    component: () => import("./pages/Policy.vue"),
    meta: {
      title: 'Privacy Policy',
      requiresAuth: true,
    }
  },
  {
    path: "/products/smart-force-for-police",
    name: "smartforceforpolice",
    exact: true,
    component: () => import("./components/productpages/SmartForceforPolice.vue"),
    meta: {
      title: 'Smart Force for Police',
      requiresAuth: true,
    }
  },
  {
    path: "/products/cmconnect",
    name: "cmconnect",
    exact: true,
    component: () => import("./components/productpages/CMConnect.vue"),
    meta: {
      title: 'CM Connect',
      requiresAuth: true,
    }
  },
  {
    path: "/products/aimllabforpolice",
    name: "aimllabforpolice",
    exact: true,
    component: () => import("./components/productpages/AIMLLabforPolice.vue"),
    meta: {
      title: 'AI ML Lab for Police',
      requiresAuth: true,
    }
  },
  {
    path: "/:pathMatch(.*)",
    name: "notfound",
    component: () => import("./pages/NotFound"),
    meta: {
      title: '404 error',
      requiresAuth: false,
    }
  },
 

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = to.meta?.title ?? "Samruddh"
})
export default router;